import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import EeiServices from '../../service/eei';
import { FaEdit, FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { tableCustomStyle } from "utils";



const Banner = () => {
    const [banner, setBanner] = useState([]);
    const [meta, setMeta] = useState({
        totalItems: 0
    })
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [selectedFile, setSelectedFile] = useState();
    const [urlContent, setUrlContent] = useState("");
    const [titleContent, setTitleContent] = useState("");
    const [is_mobile, setIsMobile] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const [editModal, setEditModal] = useState(false);
    const columns = [
        {
            name: "FILE IMAGE",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.file} alt="Banner" />
            }
        },
        {
            name: 'TITLE',
            selector: row => row.title,
        },
        {
            name: 'DESCRIPTION',
            selector: row => row.description,
        },
        {
            name: "IS ACTIVE",
            selector: row => row.isActive ? "Active" : "Inactive"
        },
        {
            name: 'ACTIONS',
            selector: row => row.id,
            cell: row => (
                <Button
                    color="info"
                    size="sm"
                    className="mr-2"
                    onClick={() =>
                        openEditModal(row)
                    }
                >
                    <FaEdit />
                </Button>
            )
        }
    ];
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const toggleEditModal = () => setEditModal(!editModal);

    const handleFileChange = (e) => setSelectedFile(e.target.files[0]);
    const handleUrlChange = (e) => setUrlContent(e.target.value);
    const handleTitleChange = (e) => setTitleContent(e.target.value);
    const handleEditorChange = (e) => setEditorContent(e.target.value);

    const [editBannerData, setEditBannerData] = useState({
        id: null,
        file: "",
        url: "",
        title: "",
        description: "",
        isActive: false,
        is_mobile: false
    });

    const openEditModal = (item) => {
        setEditBannerData({
            id: item.id,
            file: item.file,
            url: item.url,
            title: item.title,
            description: item.description,
            isActive: item.isActive,
            is_mobile: item.is_mobile
        });
        toggleEditModal();
    };

    const handleEditSubmit = async (ev) => {
        try {
            ev.preventDefault()
            const {
                id,
                file,
                url,
                title,
                description,
                isActive,
                is_mobile
            } = editBannerData;

            await editBanner(id, selectedFile, url, title, description, isActive, is_mobile);

            fetchData();
            toggleEditModal();
        } catch (error) {
            console.error('Failed to submit edited banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Save Changes',
                text: 'An error occurred while trying to save the changes.',
            });
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getBanner(page, limit);
            setBanner(result.data);
            setMeta(result.meta);
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Error when get banner",
                timer: 3000
            })
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, limit]);

    const editBanner = async (id, file, url, title, description, isActive) => {
        try {
            const eeiServices = new EeiServices();
            await eeiServices.patchBanner(id, file, url, title, description, isActive);

            fetchData();
        } catch (error) {
            console.error('Failed to edit banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Edit Banner',
                text: 'An error occurred while trying to edit the banner.',
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.BannerService(selectedFile, urlContent, titleContent, editorContent, is_mobile);
            console.log('Banner posted successfully:', result);

            Swal.fire({
                icon: 'success',
                title: 'Banner Posted!',
                text: 'The banner has been posted successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    toggleModal();
                }
            });

            setEditorContent("");
            setUrlContent("");
            setTitleContent("");
            setSelectedFile(null);
        } catch (error) {
            console.error('Failed to post banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Post Banner',
                text: 'An error occurred while trying to post the banner.',
            });
        }
    };

    const handleIsActiveChangeOnEdit = (e) => {
        const banner = editBannerData;
        editBannerData.isActive = e.target.checked;
        setEditBannerData(banner);
    }

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Banner Active</h3>
                                    <Button color="primary" onClick={toggleModal}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={banner}
                                    pagination
                                    paginationServer
                                    progressPending={loading}
                                    onChangeRowsPerPage={limit => {setLimit(limit);}}
                                    onChangePage={page => {setPage(page);}}
                                    paginationTotalRows={meta.totalItems}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            {/* {Add Modal} */}
            <Modal isOpen={modal} toggle={toggleModal} size="xl">
                <ModalHeader toggle={toggleModal}>Add Banner</ModalHeader>
                <form onSubmit={handleSubmit} >
                    <ModalBody>
                        <FormGroup>
                            <Label for="fileInput">Choose File</Label>
                            <Input
                                type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                                accept='image/*'
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="urlInput">URL</Label>
                            <Input
                                type="text"
                                id="urlInput"
                                value={urlContent}
                                onChange={handleUrlChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="titleInput">Title</Label>
                            <Input
                                type="text"
                                id="titleInput"
                                value={titleContent}
                                onChange={handleTitleChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="editor">Description</Label>
                            <Input
                                type="textarea"
                                id="descriptionInput"
                                value={editorContent}
                                onChange={handleEditorChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="checkbox"
                                onChange={e => setIsMobile(e.target.checked)}
                            />
                            <Label>Is Mobile</Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" >
                            Post Banner
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={toggleEditModal} size="xl">
                <ModalHeader toggle={toggleEditModal}>Edit Banner</ModalHeader>
                <form onSubmit={handleEditSubmit} >
                    <ModalBody>
                        <FormGroup>
                            <Label for="fileInput">Choose File</Label>
                            <Input
                                type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="editUrlInput">URL</Label>
                            <Input
                                type="text"
                                id="editUrlInput"
                                value={editBannerData.url}
                                onChange={(e) =>
                                    setEditBannerData({ ...editBannerData, url: e.target.value })
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="editTitleInput">Title</Label>
                            <Input
                                type="text"
                                id="editTitleInput"
                                value={editBannerData.title}
                                onChange={(e) =>
                                    setEditBannerData({ ...editBannerData, title: e.target.value })
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="editDescriptionInput">Description</Label>
                            <Input
                                type="textarea"
                                id="editDescriptionInput"
                                value={editBannerData.description}
                                onChange={(e) =>
                                    setEditBannerData({ ...editBannerData, description: e.target.value })
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for={"is-active-input"}>Is Active</Label>
                            <span className="clearfix" />
                            <label className="custom-toggle">
                                <input id="is-active-input" type="checkbox" defaultChecked={editBannerData.isActive} onChange={handleIsActiveChangeOnEdit} />
                                <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        </FormGroup>

                        <FormGroup>
                            <Input
                                type="checkbox"
                                checked={editBannerData.is_mobile}
                                onChange={e => setEditBannerData({...editBannerData, is_mobile: e.target.checked})}
                            />
                            <Label>Is Mobile</Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" >
                            Save Changes
                        </Button>
                        <Button color="secondary" onClick={toggleEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default Banner;
