import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    CardHeader,
    Table,
    Button,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import EeiServices, { PRODUCT_TYPE } from "../../service/eei";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2';
import CurrencyInput from "react-currency-input-field";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build"
import DataTable from "react-data-table-component";
import { tableCustomStyle } from "utils";

const ExpandableRowComponent = ({ data }) => {
    return (
      <div>
        <h4 className="ml-3 mb-0" >{data.user.name}</h4>
        <h5 className="ml-3" >{data.user.email}</h5>
        <small>
            <ul className="list-unstyled ml-3" >
                <li>Product: {data.productName}</li>
                <li>Detail Product: {data.productDetail}</li>
                <li>Event: {data.eventId.name}</li>
            </ul>
        </small>
      </div>
    );
  };
  
const EventRegister = (props) => {
    const [eventRegister, setEventRegister] = useState([]);
    const [eventRegisterMeta, setEventRegisterMeta] = useState({
        totalItems: 0
    });


    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getEventRegister(page, limit);

            setEventRegister(result.data);
            setEventRegisterMeta(result.meta);
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: "Error",
                text: "Error when get data"
            }, null, "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, limit]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.user.name,
        },
        {
            name: 'Email',
            selector: row => row.user.email,
        },
        {
            name: 'Phone',
            selector: row => row.phoneNumber,
        },
        {
            name: 'Company',
            selector: row => row.companyName,
        },
        {
            name: 'Product',
            selector: row => `${row.productName}, Detail : ${row.productDetail}`,
        },
        {
            name: 'Event',
            selector: row => row.eventId.name,
        },
    ];

    return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Event Register</h3>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    expandableRows
                                    expandableRowsComponent={ExpandableRowComponent}
                                    expandOnRowClicked
                                    data={eventRegister}
                                    paginationTotalRows={eventRegisterMeta.totalItems}
                                    pagination
                                    onChangeRowsPerPage={rows => setLimit(rows)}
                                    paginationServer
                                    onChangePage={page => setPage(page)}
                                    progressPending={loading}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

    );
};

export default EventRegister;
